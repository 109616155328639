

class Scroller {

    constructor() {
        this.supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        this.listSections = document.querySelectorAll('.homepage-catlist');
        this.isMobile = window.matchMedia('(max-width:767px)').matches;
        this.setObserver();
        this.doTriggerScroll();
        const domImgs = document.querySelectorAll('.idle, .before, .after');
        Array.from(domImgs).forEach(d=>{this.observer.observe(d);});

    }

    /* Use IntersectionObserver to detect when the images are in the parent viewport */
    setObserver() {
        if (typeof IntersectionObserver !== 'undefined') {
        this.observer = new IntersectionObserver(entries=> {
            entries.forEach(entry=> {
             
                const {isIntersecting, intersectionRatio} = entry;
             
                    
                    
                if (isIntersecting === true || intersectionRatio > 0) {
                    
                    let tImage = entry.target.dataset.image;
                entry.target.classList.add('idlefadein');
                    entry.target.style.backgroundImage = `url(${tImage})`;
                    
                    this.observer.unobserve(entry.target);
                    
                }
                
                
                
                
                
            });
            
            
            
        },{threshold:0.1,rootMargin:'1%'});
        
        }
        
    }

    doTriggerScroll() {

        Object.keys(this.listSections).forEach((s) => {
            let _sec = this.listSections[s];


            let _scrollBlock = _sec.querySelector('ul');
            if (!_scrollBlock) {
                return;
            }

           
            let scrollType = _scrollBlock.dataset.scrolltype;

            let _scrollKids = _scrollBlock.querySelectorAll('li');


            let _navs = _sec.querySelectorAll('.nav');
            let targetIdx = 0;
            let rect = 0;
            Object.keys(_navs).forEach((n) => {
                let _nav = _navs[n];
                _nav.addEventListener('click', (a) => {
                    /* check the scroll position so we can make the buttons display or disappear according to where it is */
                    this.setButtonState(a);



                    var maxIdx = _scrollKids.length - 1;


                    if (a.target.classList.contains('next')) {

                        targetIdx = (targetIdx < maxIdx) ? targetIdx + 1 : targetIdx;
                        rect = (scrollType) ? (_scrollBlock.scrollLeft + _scrollBlock.offsetWidth) : _scrollKids[targetIdx].offsetLeft;
                     
                    } else {
                        targetIdx = (targetIdx > 0) ? targetIdx - 1 : 0;
                        rect = (scrollType) ? (_scrollBlock.scrollLeft - _scrollBlock.offsetWidth) : _scrollKids[targetIdx].offsetLeft;
                    }


                    if (this.supportsNativeSmoothScroll) {
                        _scrollBlock.scrollTo({left: rect, top: 0, behavior: 'smooth'});
                          
                    } else {
                        
                        this.doPseudoScrollTo(_scrollBlock, rect);
                    }
                    
                    
                });
                
                
                
            });
        });
    }

    /* some browsers can't handle scrollTo options so we'll recreate the smooth function here */
    doPseudoScrollTo(el, pos) {

        let lPos = pos;
        jQuery(el).animate({scrollLeft: lPos}, 'slow');

    }

    /* determine which buttons to display */
    setButtonState(a) {
      
        let c = setTimeout(()=>{
              let _parent = a.target.closest('header');
              let _l = _parent.nextElementSibling.scrollLeft;
              let _w = _parent.nextElementSibling.scrollWidth;
              let _os = _parent.nextElementSibling.offsetWidth;
              
          
               var isEnd = _l === (_w - _os);
             
              if (_l === 0) {
                  _parent.querySelector('.nav.prev').style.visibility = 'hidden';
              } else if (_l > 0 && _l < _w + _os) {
                 _parent.querySelector('.nav.prev').style.visibility = 'visible'; 
              } 
              
            if (isEnd) {
                 
                   _parent.querySelector('.nav.next').style.visibility = 'hidden';
                  
              } else if (!isEnd) {
                  
                   _parent.querySelector('.nav.next').style.visibility = 'visible';
              }
       
        
        
        },750,a);
        
      
       
       }

}


window.addEventListener('DOMContentLoaded', () => {
    new Scroller();
});
